<script setup lang="ts">
import uploadProfile from '@/assets/img/avatar-placeholder.svg';

withDefaults(
  defineProps<{
    src?: string;
    fallback?: string;
    alt?: string;
    size?: string | number;
    rounded?: number;
  }>(),
  {
    src: undefined,
    fallback: uploadProfile,
    rounded: 100,
    alt: '',
    size: '136px',
  }
);

const emits = defineEmits<{
  (e: 'error'): void;
}>();
</script>

<template>
  <v-avatar :rounded="rounded" :size="size">
    <v-img :src="src || uploadProfile" :alt="alt" @error="emits('error')">
      <template #error>
        <v-img :src="uploadProfile" alt="Profile Picture" />
      </template>
    </v-img>
  </v-avatar>
</template>

<style></style>
